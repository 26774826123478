article {
  min-height: 70vh;
  margin: 3em 2em;
}

@media (min-width: 43em) {
  article {
    margin: 3em auto;
    max-width: 39em;
  }
}

@media (min-width: 62em) {
  article {
    max-width: 50em;
  }
}

@media (min-width: 82em) {
  article {
    max-width: 60em;
  }
}
