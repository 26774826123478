@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;1,200;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital@0;1&display=swap');

body {
  font-family: 'Playfair Display', serif;
  font-size: $baseFontSize;
  color: $textColor;
  background-color: $backgroundColor;
}

h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
}

h1 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 400;
}

h2 {
  font-size: 2rem;

  &::after {
    content: '';
    display: block;
    border-top: 1px solid $textColor;
  }
}

blockquote {
  background-color: $blockColor;
  margin: 1em;
  padding: 0.25em 1em;
}

pre, code, samp, kbd {
  font-family: 'IBM Plex Mono', monospace;
}

mark {
  box-sizing: content-box;
  background-color: $markerColor;
}

a {
  color: $textColor;
  text-decoration: underline;
  text-decoration-color: $linkColor;
  -webkit-text-decoration-color: $linkColor;

  &:hover {
    color: $linkColor;
  }
}

::selection {
  background-color: $selectionColor;
  color: $backgroundColor
}