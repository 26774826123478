// palette
$ivory: #f7f5f3;
$eggshell: #efeae3;
$indianRed: #da5552;
$englishViolet: #563c5c;
$amazonGreen: #3f6c51;
$amazonMediumGreen: #417b5a;
$mintGreen: #b9ffb7;
$almostBlack: #212023;

// color assignment
$backgroundColor: $ivory;
$blockColor: $eggshell;
$textColor: $almostBlack;
$linkColor: $indianRed;
$markerColor: $mintGreen;
$selectionColor: $englishViolet;

// typography
$baseFontSize: 12pt; // 16px
